// eslint-disable-next-line import/no-unresolved
import { FormDataType, CompetitionFormBodyType } from 'components/CompetitionForm/models';

const preparePhoneNumber = (phoneNumber: string): string => phoneNumber.replace('+', '');

export const prepareCompetitionFromBody = (formData: FormDataType) => {
  const { email, firstName, lastName, phoneNumber } = formData;

  const signupDate = new Date().toISOString();

  const requestBody = {
    cdp: {
      programCode: process.env.GATSBY_CDP_PROGRAM_CODE,
      brandOrgCode: process.env.GATSBY_CDP_BRAND_ORG_CODE,
      accountSource: process.env.GATSBY_CDP_ACCOUNT_SOURCE,
    },
    data: {
      FirstName: firstName,
      LastName: lastName,
      LanguageCode: 'DEU',
      email,
      Emails: [
        {
          EmailAddress: email,
          DeliveryStatus: 'G',
        },
      ],
      Phones:
        phoneNumber !== ''
          ? [
              {
                PhoneNumber: preparePhoneNumber(phoneNumber),
                DeliveryStatus: 'G',
                AcceptsText: true,
              },
            ]
          : undefined,
      SourceCode: process.env.GATSBY_CDP_SOURCE_CODE,
      TierCode: process.env.GATSBY_CDP_TIER_CODE,
      JsonExternalData: {
        Agreements: [
          {
            BusinessId: process.env.GATSBY_CDP_BUSINESS_ID,
            RevisionId: process.env.GATSBY_CDP_REVISION_ID,
            ConsentAcceptedInd: true,
            ConsentDesc: 'Consent Description',
            MandatoryInd: true,
            AgreementDate: signupDate,
            ActivityDate: signupDate,
            Status: 'A',
          },
        ],
        ProfileSubscriptions: [
          {
            SubscriptionId: process.env.GATSBY_CDP_SUBSCRIPTION_ID,
            OptSource: 'WEB',
            ChannelCode: 'EM',
            OptStatus: true,
            ActivityDate: signupDate,
            Status: 'A',
            JsonExternalData: {
              ContactPointValue: email,
              EmailOptFlag: 'Y',
            },
          },
        ],
      },
    },
  } as CompetitionFormBodyType;

  return { requestBody, emailAddress: email };
};

export default {
  prepareCompetitionFromBody,
};
