import UmbracoImage from 'common/Image/UmbracoImage';
import React, { FC } from 'react';
import { ThankYouModalProps } from './models';

import './ThankYouModal.scss';

const ThankYouModal: FC<ThankYouModalProps> = ({
  formPreview,
  title,
  redirectItems,
  setThankYouModal,
}) => (
  <div className="competition-thank-you-modal">
    <button
      onClick={() => setThankYouModal(false)}
      type="button"
      aria-label="close modal"
      className="competition-thank-you-modal__close-button"
    />
    <div className="competition-thank-you-modal__form-preview-image">
      <UmbracoImage objectFit="contain" image={formPreview} alt="preview image" />
    </div>
    <h2 className="competition-thank-you-modal__title">{title}</h2>
    {redirectItems ? (
      <div className="competition-thank-you-modal__confirmation d-flex">
        {redirectItems.map(({ redirectImage }, index) => (
          <UmbracoImage
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            objectFit="contain"
            className="competition-thank-you-modal__image"
            image={redirectImage}
            alt="confirmation image"
          />
        ))}
      </div>
    ) : null}
  </div>
);

export default ThankYouModal;
