import React, { FC } from 'react';
import classNames from 'classnames';
import FormControlTooltip from '../common/FormControlTooltip';
import FormControlInput from '../common/FormControlInput';
import FormControlErrors from '../common/FormControlErrors';

const FormControl: FC<SignUpTypes.FormControlProps> = ({
  type,
  label,
  fieldID,
  placeholder,
  handleValidation,
  regexPattern,
  tooltipMessage,
  errors,
  mandatoryValidationMessage,
  additionalValidationMessage,
  register,
  linkedLabels,
  required,
}) => (
  <>
    <div
      className={classNames('signup-form__control', {
        [`signup-form__control--${type[0]}`]: type,
      })}
      key={label}
      data-testid="signup-form-control"
    >
      <FormControlInput
        {...{
          type,
          label,
          fieldID,
          placeholder,
          regexPattern,
          register,
          handleValidation,
          tooltipMessage,
          linkedLabels,
          required,
        }}
      />
      <FormControlTooltip {...{ tooltipMessage }} />
    </div>
    <FormControlErrors
      {...{ errors, fieldID, mandatoryValidationMessage, additionalValidationMessage }}
    />
  </>
);

export default FormControl;
