import React, { FC } from 'react';

import UmbracoImage from 'common/Image/UmbracoImage';
import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';

import { CompetitionBannerProps } from './models';
import './CompetitionBanner.scss';

const CompetitionBanner: FC<CompetitionBannerProps> = ({ altText, brandLogo, bannerText }) => (
  <div className="competition-banner">
    <UmbracoImage
      objectFit="contain"
      alt={altText}
      image={brandLogo}
      className="competition-banner__logo"
    />
    <div className="competition-banner__text">
      <DangerouslySetInnerHtml html={bannerText} />
    </div>
  </div>
);

export default CompetitionBanner;
