import React, { FC } from 'react';
import classNames from 'classnames';
import { RADIO_INPUT_TYPE, CHECKBOX_INPUT_TYPE } from 'components/SignUpForm/constants';
import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';

const FormControlInput: FC<SignUpTypes.FormControlInputProps> = ({
  type,
  label,
  fieldID,
  regexPattern,
  register,
  handleValidation,
  tooltipMessage,
  linkedLabels,
  required = true,
}) => {
  return (
    <div
      className={classNames('signup-form__input-container', {
        [`signup-form__input-container--${type[0]}`]: type,
      })}
      data-testid="signup-form-input-container"
    >
      <input
        className={classNames('signup-form__input', {
          [`signup-form__input--${type[0]}`]: type,
        })}
        type={type[0]}
        id={type[0] === RADIO_INPUT_TYPE ? label : fieldID}
        name={fieldID}
        ref={register({
          required,
          validate: () => handleValidation(regexPattern, fieldID),
        })}
        value={type[0] === RADIO_INPUT_TYPE ? label : undefined}
      />
      <label
        className={classNames('signup-form__label', {
          'signup-form__label--with-tooltip': tooltipMessage,
          [`signup-form__label--${type[0]}`]: type,
        })}
        htmlFor={type[0] === RADIO_INPUT_TYPE ? label : fieldID}
      >
        {type[0] !== CHECKBOX_INPUT_TYPE ? label : null}
      </label>
      {linkedLabels ? <DangerouslySetInnerHtml html={linkedLabels} /> : null}
    </div>
  );
};

export default FormControlInput;
