import React, { FC } from 'react';

const FormControlTooltip: FC<SignUpTypes.FormControlTooltipProps> = ({ tooltipMessage }) =>
  tooltipMessage ? (
    <button type="button" className="signup-form__tooltip" data-testid="signup-form-tooltip">
      <div className="signup-form__tooltip-icon" />
      <div className="signup-form__tooltip-description">{tooltipMessage}</div>
    </button>
  ) : null;

export default FormControlTooltip;
