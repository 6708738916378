import React, { FC } from 'react';
import FormControlTooltip from '../common/FormControlTooltip';
import FormControlInput from '../common/FormControlInput';
import FormControlErrors from '../common/FormControlErrors';

const FormGroupControl: FC<SignUpTypes.FormGroupControlProps> = ({
  fieldID,
  radioGroupLabel,
  tooltipMessage,
  errors,
  mandatoryValidationMessage,
  register,
  handleValidation,
  radioCheckBoxes,
}) => (
  <div
    className="signup-form__control signup-form__control--radio-group"
    data-testid="signup-form-group-control"
  >
    <p className="signup-form__label signup-form__label--radio-group">{radioGroupLabel}</p>
    <FormControlTooltip {...{ tooltipMessage }} />
    <div className="signup-form__control-container">
      {radioCheckBoxes!.map(({ label, type }) => (
        <div className="signup-form__control signup-form__control--radio" key={label}>
          <FormControlInput
            {...{ label, radioGroupLabel, type, register, handleValidation, fieldID }}
          />
        </div>
      ))}
    </div>
    <FormControlErrors {...{ errors, fieldID, mandatoryValidationMessage }} />
  </div>
);

export default FormGroupControl;
