import React, { FC } from 'react';

import { graphql } from 'gatsby';

import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';
import UmbracoImage from 'common/Image/UmbracoImage';
import { CompetitionRewardsProps } from './models';

import './CompetitionRewards.scss';

const CompetitionRewards: FC<CompetitionRewardsProps> = ({
  rules,
  rewardTitle,
  rewardSubtitle,
  rewardImage,
  imageAlt,
}) => (
  <div className="competition-rewards__rules">
    <div className="competition-rewards__left-section">
      <DangerouslySetInnerHtml html={rules} />
    </div>
    <div className="competition-rewards__right-section">
      <p className="competition-rewards__right-title">{rewardTitle}</p>
      <p className="competition-rewards__right-subtitle">{rewardSubtitle}</p>

      <UmbracoImage
        objectFit="contain"
        alt={imageAlt}
        image={rewardImage}
        className="competition-rewards__dryer"
      />
    </div>
  </div>
);

export const rewardsSectionFragment = graphql`
  fragment RewardsSectionFragment on RewardsSectionType {
    title
    subtitle
    image {
      ...umbracoImage
    }
  }
`;

export default CompetitionRewards;
