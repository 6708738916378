import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { CompetitionFormBodyType } from 'components/CompetitionForm/models.d';
import { useState } from 'react';
import { RequestStatusType } from './types';

const useCompetitionPost = (url) => {
  const [requestStatus, setRequestStatus] = useState<RequestStatusType>();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const postForm = async (
    reqBody: CompetitionFormBodyType,
    emailAddress: string,
    emailExistsValidationMessage: string
  ) => {
    if (!executeRecaptcha) {
      setRequestStatus({
        state: 'error',
        message: 'Captcha fetch error. Please try again later.',
      });

      return;
    }
    const gRecaptchaResponse = await executeRecaptcha('competitionForm');
    setRequestStatus({
      state: 'loading',
    });

    const body = {
      ...reqBody,
      recaptchaResponse: gRecaptchaResponse,
    };

    const response = await fetch(`${url}`, {
      method: 'POST',
      body: JSON.stringify(body),
    });

    if (response.status >= 200 && response.status <= 299) {
      setRequestStatus({
        state: 'success',
      });
    } else if (response.status === 409 && emailExistsValidationMessage) {
      setRequestStatus({
        state: 'error',
        message: emailExistsValidationMessage.replace('{{emailAddress}}', emailAddress),
      });
    } else {
      setRequestStatus({
        state: 'error',
        message: `${response.status} ${response.statusText}`,
      });
    }
  };

  return {
    postForm,
    requestStatus,
  };
};

export default useCompetitionPost;
