import React, { FC } from 'react';

const FormControlErrors: FC<SignUpTypes.FormControlErrorsProps> = ({
  errors,
  fieldID,
  mandatoryValidationMessage,
  additionalValidationMessage,
}) =>
  errors && errors[fieldID] ? (
    <ul className="signup-form__errors-list">
      {errors[fieldID]?.type === 'required' ? (
        <li className="signup-form__error-item">{mandatoryValidationMessage}</li>
      ) : null}
      {errors[fieldID] && additionalValidationMessage ? (
        <li className="signup-form__error-item">{additionalValidationMessage}</li>
      ) : null}
    </ul>
  ) : null;

export default FormControlErrors;
