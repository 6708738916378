import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';
import { useLocation } from '@reach/router';

import { SeoProps } from './models';

const Seo: FC<SeoProps> = ({ description, lang, keywords, title, externalHreflangs, ogImage }) => {
  const { siteSettings, brandSettings } = useStaticQuery(graphql`
    query SettingsQuery {
      siteSettings {
        siteName
        lang
      }
      brandSettings {
        brandName
      }
    }
  `);

  const siteTitle = siteSettings.siteName || '';
  const titleTemplate = title === siteTitle ? '%s' : `%s | ${siteTitle}`;
  const siteAuthor = brandSettings.brandName;
  const siteLang = lang || siteSettings.lang;
  const meta = [
    {
      name: 'description',
      content: description,
    },
    {
      property: 'og:title',
      content: title,
    },
    {
      property: 'og:description',
      content: description,
    },
    {
      property: 'og:type',
      content: 'website',
    },
    {
      name: 'twitter:card',
      content: 'summary',
    },
    {
      name: 'twitter:creator',
      content: siteAuthor,
    },
    {
      name: 'twitter:title',
      content: title,
    },
    {
      name: 'twitter:description',
      content: description,
    },
    {
      property: 'og:url',
      content: useLocation().href,
    },
    ...(keywords?.length
      ? [
          {
            name: 'keywords',
            content: keywords.join(', '),
          },
        ]
      : []),
    ...(ogImage ? [{ property: 'og:image', content: ogImage }] : []),
  ];

  return (
    <Helmet htmlAttributes={{ lang: siteLang }} {...{ title, titleTemplate, meta }}>
      {externalHreflangs?.map((link) => {
        return <link rel="alternate" href={link.value} hrefLang={link.key} />;
      })}
    </Helmet>
  );
};

export default Seo;
