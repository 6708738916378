import React, { FC, useEffect, useMemo, useState } from 'react';
import { graphql } from 'gatsby';
import { Layout } from 'layout';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import Seo from 'common/Seo';
import PageSchema from 'common/PageSchema';

import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';
import CompetitionForm from 'components/CompetitionForm';
import CompetitionBanner from 'components/CompetitionBanner';
import CompetitionRewards from 'components/CompetitionRewards';
import runOnUserInteraction from 'utils/runOnUserInteraction';
import UmbracoImage from 'common/Image/UmbracoImage';
import { CompetitionPageProps } from './models';
import './CompetitionPage.scss';

const CompetitionPage: FC<CompetitionPageProps> = ({
  data: {
    umbracoCompetition: {
      langProps: { urls, lang },
      seoProps: {
        seoMetaTitle,
        seoMetaDescription,
        seoMetaKeywords,
        seoExternalHreflangs,
        ogImage,
      },
      title,
      bannerImage,
      bannerText,
      rulesSection,
      form,
      signUpAgreements: agreements,
      thankYouPage,
      rewardsSection,
      bottomSection,
    },
  },
}) => {
  const [shouldLoadGoogleReCaptchaProvider, setShouldLoadGoogleReCaptchaProvider] = useState<
    boolean
  >(false);

  useEffect(() => {
    runOnUserInteraction(() => setShouldLoadGoogleReCaptchaProvider(true));
  }, []);

  const renderForm = useMemo(
    () =>
      shouldLoadGoogleReCaptchaProvider ? (
        <GoogleReCaptchaProvider
          reCaptchaKey={process.env.GATSBY_GOOGLE_CAPTCHA_PUBLIC}
          scriptProps={{ async: true, defer: true, appendTo: 'body' }}
        >
          <CompetitionForm {...form} {...{ lang, agreements, thankYouPage }} />
        </GoogleReCaptchaProvider>
      ) : (
        <CompetitionForm {...form} {...{ lang, agreements, thankYouPage }} />
      ),
    [form, lang, agreements, shouldLoadGoogleReCaptchaProvider]
  );

  return (
    <Layout langProps={{ urls, lang }} className="competition-page">
      <Seo
        {...{
          title: seoMetaTitle,
          description: seoMetaDescription,
          keywords: seoMetaKeywords,
          externalHreflangs: seoExternalHreflangs,
          ogImage,
          lang,
        }}
      />
      <PageSchema
        type="WebPage"
        name={seoMetaTitle}
        data={{
          metaTitle: seoMetaTitle,
          metaDescription: seoMetaDescription,
          metaKeywords: seoMetaKeywords,
        }}
      />
      {bannerText && bannerImage ? (
        <CompetitionBanner altText={title} bannerText={bannerText} brandLogo={bannerImage} />
      ) : null}
      {rewardsSection ? (
        <CompetitionRewards
          rules={rulesSection}
          rewardTitle={rewardsSection.title}
          rewardSubtitle={rewardsSection.subtitle}
          imageAlt={rewardsSection.subtitle}
          rewardImage={rewardsSection.image}
        />
      ) : null}
      {form ? <div className="competition-page__form">{renderForm}</div> : null}

      {bottomSection ? (
        <div className="competition-page__bottom-banner">
          <UmbracoImage
            alt={title}
            image={bottomSection.image}
            className="competition-page__bottom-image"
          />
          <div className="competition-page__lds">
            <DangerouslySetInnerHtml html={bottomSection.description} />
            <DangerouslySetInnerHtml html={bottomSection.privacyPolicy} />
          </div>
        </div>
      ) : null}
    </Layout>
  );
};

export const query = graphql`
  query CompetitionQuery($pageId: String, $lang: String) {
    umbracoCompetition(id: { eq: $pageId }, langProps: { lang: { eq: $lang } }) {
      langProps {
        urls {
          ...languageUrls
        }
        lang
      }
      seoProps {
        seoMetaTitle
        seoMetaKeywords
        seoMetaDescription
        seoExternalHreflangs {
          key
          value
        }
        ogImage
      }
      title
      bannerImage {
        ...umbracoImage
      }
      bannerText
      rulesSection
      rewardsSection {
        ...RewardsSectionFragment
      }
      form {
        ...SignUpFormFragment
      }
      thankYouPage {
        ...ThankYouPageFragment
      }
      signUpAgreements {
        ...SignUpAgreementsFragment
      }
      bottomSection {
        ...BottomSectionFragment
      }
    }
  }
`;

export default CompetitionPage;
