import React, { FC, useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import Button from 'common/Button';
import { prepareCompetitionFromBody } from 'utils/competitionForm';
import useCompetitionPost from 'utils/hooks/useCompetitionPost';

import FormGroupControl from 'components/SignUpForm/FormGroupControl';
import FormControl from 'components/SignUpForm/FormControl';
import { FormDataType } from './models';

import './CompetitionForm.scss';
import { PHONE_FIELD_ID } from './constants';
import ThankYouModal from './ThankYouModal';

const CompetitionForm: FC<SignUpTypes.ConstipationFormType> = ({
  mandatoryValidationMessage,
  emailExistsValidationMessage,
  button,
  inputList,
  title,
  thankYouPage,
}) => {
  const [submitErrorMessage, setSubmitErrorMessage] = useState<string | undefined>('');
  const [isLoading, setLoading] = useState<boolean>(false);
  const [renderThankYouModal, setThankYouModal] = useState<boolean>(false);

  const { register, errors, handleSubmit, getValues } = useForm({
    mode: 'onChange',
  });

  const { postForm, requestStatus } = useCompetitionPost(process.env.GATSBY_CDP_LAMBDA_URL);

  const onSubmit = async (formData: FormDataType) => {
    const { requestBody, emailAddress } = prepareCompetitionFromBody(formData);
    await postForm(requestBody, emailAddress, emailExistsValidationMessage);
  };

  const handleValidation = (regexPattern, fieldID): boolean => {
    let validated = true;
    const fieldValue = getValues(fieldID);

    if (regexPattern) {
      const regex = new RegExp(regexPattern);
      validated = regex.test(fieldValue);
    }

    return validated;
  };

  const { ariaLabel, text } = button[0];

  useEffect(() => {
    if (requestStatus?.state === 'success') {
      setThankYouModal(true);
      setLoading(false);
    }
    if (requestStatus?.state === 'error') {
      setSubmitErrorMessage(requestStatus?.message);
      setLoading(false);
    }
    if (requestStatus?.state === 'loading') {
      setLoading(true);
    }
  }, [requestStatus]);

  return (
    <div className="signup-form " data-testid="signup-form">
      {submitErrorMessage ? (
        <p className="signup-form__form-error-message">{submitErrorMessage}</p>
      ) : null}
      {title ? <h2 className="signup-form__title">{title}</h2> : null}
      <form onSubmit={handleSubmit(onSubmit)}>
        {inputList.map(
          ({
            additionalValidationMessage,
            label,
            fieldID,
            placeholder,
            regexPattern,
            type,
            tooltipMessage,
            linkedLabels,
            radioGroupLabel,
            radioCheckBoxes,
          }) =>
            radioGroupLabel ? (
              <FormGroupControl
                key={radioGroupLabel}
                {...{
                  fieldID,
                  radioGroupLabel,
                  tooltipMessage,
                  errors,
                  mandatoryValidationMessage,
                  register,
                  handleValidation,
                  radioCheckBoxes,
                }}
              />
            ) : (
              <FormControl
                key={label}
                required={fieldID !== PHONE_FIELD_ID}
                {...{
                  type,
                  label,
                  fieldID,
                  placeholder,
                  handleValidation,
                  regexPattern,
                  tooltipMessage,
                  errors,
                  mandatoryValidationMessage,
                  additionalValidationMessage,
                  register,
                  linkedLabels,
                }}
              />
            )
        )}
        <Button
          type="submit"
          className="signup-form__submit"
          {...{ ariaLabel }}
          disabled={isLoading}
        >
          {text}
        </Button>
      </form>

      {renderThankYouModal ? <ThankYouModal {...thankYouPage} {...{ setThankYouModal }} /> : null}
    </div>
  );
};

export default CompetitionForm;
